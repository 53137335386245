import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const closePushPro = async (data) => {
  const response = await matchfyClient.put('/push-pro/close', data)
  return response.data
}

export const createPushPro = async (data) => {
  const response = await matchfyClient.post('/push-pro', data)
  return response.data
}

export const getPushPro = async (id) => {
  const response = await matchfyClient.get(`/push-pro/detail/${id}`)
  return response.data
}

export const getPushProDashboard = async () => {
  const response = await matchfyClient.get('/push-pro/dashboard')
  return response.data
}

export const reportPushPro = async (data) => {
  const response = await matchfyClient.post('/push-pro/dispute', data)
  return response.data
}

export const updatePushProStatus = async (data) => {
  const response = await matchfyClient.put('/push-pro/status', data)
  return response.data
}

export const undoReportPushPro = async (id) => {
  const response = await matchfyClient.delete(`/push-pro/dispute`, {
    data: {
      id,
    },
  })
  return response.data
}

export const isReportedPushPro = async (id) => {
  const response = await matchfyClient.get(`/push-pro/is-in-dispute/${id}`)
  return response.data
}

export const listPushPro = async (params, type) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const response = await matchfyClient.get(`/push-pro/${type}`, {
    params: {
      limit,
      offset,
    }
  })

  return response.data
}

const endpoints = {
  closePushPro,
  createPushPro,
  getPushPro,
  getPushProDashboard,
  isReportedPushPro,
  listPushPro,
  reportPushPro,
  undoReportPushPro,
  updatePushProStatus
}

export default endpoints
