import { matchfyClient } from '@/lib'

export const devUpdatePlan = async (data) => {
  const response = await matchfyClient.post('/dev/plan-switch', { ...data })
  return response.data
}

export const devRefreshUserCache = async () => {
  const response = await matchfyClient.post('/dev/refresh-user')
  return response.data
}

export const devRemoveArtist = async () => {
  const response = await matchfyClient.get(`/dev/remove-artist`)
  return response.data
}

export const devPurgeUser = async () => {
  const response = await matchfyClient.get(`/dev/purge-user`)
  return response.data
}

export const devPushProReset = async (id) => {
  const response = await matchfyClient.get(`/dev/push-pro-reset/${id}`)
  return response.data
}

export const devVerifyUser = async () => {
  const response = await matchfyClient.get(`/dev/verify-user`)
  return response.data
}

const endpoints = {
  devPurgeUser,
  devPushProReset,
  devRefreshUserCache,
  devRemoveArtist,
  devUpdatePlan,
  devVerifyUser
}

export default endpoints
