import { matchfyClient } from '@/lib'

export const sendContactRequest = async (data) => {
  const response = await matchfyClient.post(`/contacts/send`, data)
  return response.data
}

const endpoints = {
  sendContactRequest,
}

export default endpoints
