import { matchfyClient } from '@/lib'

export const createRequest = async (type, formData) => {
  const response = await matchfyClient.post(`requests`, { type, formData })
  return response.data
}

export const hasRequest = async (type) => {
  const response = await matchfyClient.get(`requests/exists/${type}`)
  return response.data
}

export const getRequest = async (type) => {
  const response = await matchfyClient.get(`requests/${type}`)
  return response.data
}

const endpoints = {
  createRequest,
  hasRequest,
  getRequest
}

export default endpoints
