'use client'

import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from "@/data"

const checkMxp = () => {
  if (MIXPANEL_TOKEN && mixpanel) {
    return true
  }

  return false
}

export const mxp = {
  init: () => {
    try {
      if (MIXPANEL_TOKEN) {
        mixpanel.init(MIXPANEL_TOKEN)
      }
    } catch (error) {
      console.error("Failed to initialize Mixpanel:", error)
    }
  },
  identify: (id) => {
    try {
      if (!checkMxp()) return
      mixpanel.identify(id)
    } catch (error) {
      console.error("Failed to identify user in Mixpanel:", error)
    }
  },
  alias: (id) => {
    try {
      if (!checkMxp()) return
      mixpanel.alias(id)
    } catch (error) {
      console.error("Failed to alias user in Mixpanel:", error)
    }
  },
  track: (name, props) => {
    try {
      if (!checkMxp()) return
      mixpanel.track(name, props)
    } catch (error) {
      console.error(`Failed to track '${name}' in Mixpanel:`, error)
    }
  },
  people: {
    set: (props) => {
      try {
        if (!checkMxp()) return
        mixpanel.people.set(props)
      } catch (error) {
        console.error("Failed to set people properties in Mixpanel:", error)
      }
    },
  },
}
