import { matchfyClient } from '@/lib'

export const listGenres = async (query) => {
  const response = await matchfyClient.get('/genres', { params: { q: query } })
  return response.data
}

export const validateGenres = async (genres) => {
  const response = await matchfyClient.post('/genres/validate', { genres })
  return response.data
}

const endpoints = {
  listGenres,
  validateGenres,
}

export default endpoints
