import { isAxiosError } from 'axios'
import { CURRENCY, PLANS, PRODUCTION } from '@/data'
import { mxp } from '@/lib'

export const EVENTS = {
  ACCELERATE_SUBMISSION: 'AccelerateSubmission',
  ARTIST_ADD: 'ArtistAdd',
  ARTIST_REMOVE: 'ArtistRemove',
  BILLING_DATA_UPDATE: 'BillingDataUpdate',
  CONTACT_FORM_SUBMISSION: 'ContactFormSubmission',
  DISABLE_AUTO_RENEWAL: 'DisableAutoRenewal',
  EMAIL_VERIFICATION: 'EmailVerification',
  ENABLE_AUTO_RENEWAL: 'EnableAutoRenewal',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  MATCH_QUERY: 'MatchQuery',
  NEW_USER: 'NewUser',
  PURCHASE: 'Purchase',
  PURCHASE_AGENCY: 'PurchaseAgency',
  PURCHASE_ARTIST: 'PurchaseArtist',
  PURCHASE_VIP: 'PurchaseVIP',
  PUSH_TO_MAGAZINE: 'PushToMagazine',
  PUSH_TO_PLAYLIST: 'PushToPlaylist',
  PUSH_TO_TIKTOK: 'PushToTikTok',
  RADIO_VIEW: 'RadioView',
  UPGRADE_PLAN_CTA: 'UpgradePlanCta',
  VIP_CAMPAIGN_STEP_1: 'VipCampaignStep1',
  VIP_CAMPAIGN_STEP_2: 'VipCampaignStep2',
  VIP_CAMPAIGN_STEP_3: 'VipCampaignStep3',
  VIP_CAMPAIGN_STEP_4: 'VipCampaignStep4',
  VIP_CAMPAIGN_STEP_5: 'VipCampaignStep5'
}

export const GTAG_ADVANCED_CONVERSION = {
  PURCHASE: 'AW-753455424/_ohtCKz2npcYEMCio-cC',
  SIGN_UP: 'AW-753455424/nf4eCOrAn5cYEMCio-cC',
}

const waitForPixel = (timeout = 1000, maxRetries = 10) => {
  return new Promise((resolve, reject) => {
    let attempts = 0

    const checkPixel = () => {
      if (typeof window.fbq === 'function') {
        resolve()
      } else if (attempts >= maxRetries) {
        reject(new Error(`fbq not loaded after ${maxRetries} attempts`))
      } else {
        attempts++
        setTimeout(checkPixel, timeout)
      }
    }

    checkPixel()
  })
}

const trackNewUser = async (eventId, payload) => {
  const { user } = payload

  mxp.track(EVENTS.NEW_USER, {
    eventID: eventId,
    user: user?.spotifyId,
    email: user?.contacts?.email,
    phone: user?.contacts?.phone
  })

  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration', {
      eventID: eventId,
    })
  }

  if (window.gtag) {
    window.gtag('event', 'sign_up')

    window.gtag('set', 'user_data', {
      userId: user?.spotifyId,
      email: user?.contacts?.email,
      phone_number: user?.contacts?.phone,
    })

    window.gtag('event', 'sign_up', {
      send_to: GTAG_ADVANCED_CONVERSION.SIGN_UP,
    })
  }

  if (window.ttq) {
    window.ttq.track('CompleteRegistration', {
      contents: [
        {
          content_name: user?.contacts?.email,
        },
      ],
    })
  }
}

const trackPurchase = async (eventId, payload) => {
  const { status, price, plan, user } = payload

  mxp.track(EVENTS.PURCHASE, {
    eventID: eventId,
    value: parseFloat(price),
    currency: CURRENCY,
    plan,
    status,
    user: user?.spotifyId
  })

  if (window.dataLayer && user) {
    window.dataLayer.push({
      userId: user?.spotifyId,
      email: user?.contacts?.email,
      phone_number: user?.contacts?.phone,
    })
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      conversionValue: parseFloat(price),
      value: parseFloat(price),
    })
  }

  if (window.gtag) {
    window.gtag('event', 'purchase', {
      value: price,
      currency: CURRENCY,
    })

    window.gtag('event', 'purchase', {
      value: parseFloat(price),
      currency: CURRENCY,
      items: [
        {
          plan,
          name: plan,
          price,
        },
      ],
    })

    window.gtag('event', 'conversion', {
      send_to: GTAG_ADVANCED_CONVERSION.PURCHASE,
      value: parseFloat(price),
      currency: CURRENCY,
    })
  }

  if (window.fbq) {
    window.fbq(
      'track',
      EVENTS.PURCHASE,
      {
        value: price,
        currency: CURRENCY,
      },
      { eventID: eventId }
    )
  }

  if (window.fbq && plan === PLANS.ARTIST) {
    window.fbq(
      'track',
      EVENTS.PURCHASE_ARTIST,
      {
        value: price,
        currency: CURRENCY,
      },
      { eventID: eventId }
    )
  }

  if (window.fbq && plan === PLANS.AGENCY) {
    window.fbq(
      'track',
      EVENTS.PURCHASE_AGENCY,
      {
        value: price,
        currency: CURRENCY,
      },
      { eventID: eventId }
    )
  }

  if (window.fbq && plan === PLANS.VIP) {
    window.fbq(
      'track',
      EVENTS.PURCHASE_VIP,
      {
        value: price,
        currency: CURRENCY,
      },
      { eventID: eventId }
    )
  }

  if (window.ttq) {
    window.ttq.track('CompletePayment', {
      value: price,
      currency: CURRENCY,
      status,
      plan,
      content_id: eventId,
      event_id: eventId,
    })
  }
}

const initiateCheckout = async (eventId, payload) => {
  const { slug: planSlug, title, price } = payload

  mxp.track(EVENTS.INITIATE_CHECKOUT, {
    eventID: eventId,
    plan: planSlug,
    title,
    value: price,
    currency: CURRENCY
  })

  if (window.fbq) {
    window.fbq(
      'track',
      'InitiateCheckout',
      {
        value: price,
        currency: CURRENCY,
        content_name: title,
      },
      { eventID: eventId }
    )
  }

  if (window.gtag) {
    window.gtag('event', 'begin_checkout', {
      value: price,
      currency: CURRENCY,
      items: [
        {
          planSlug,
          name: title,
          price,
        },
      ],
    })
  }

  if (window.ttq) {
    window.ttq.track('InitiateCheckout', {
      value: price,
      currency: CURRENCY,
      contents: [
        {
          content_name: title,
          content_id: planSlug,
          price: price,
        },
      ],
    })
  }
}

export const trackEvent = async (slug, payload, eventId) => {
  if (!PRODUCTION) return

  mxp.init()

  await waitForPixel()

  try {
    switch (slug) {
      case EVENTS.INITIATE_CHECKOUT:
        await initiateCheckout(eventId, payload)
        break
      case EVENTS.NEW_USER:
        await trackNewUser(eventId, payload)
        if (payload.user?.spotifyId) {
          mxp.identify(payload.user.spotifyId)
          mxp.people.set({
            $email: payload.user?.contacts?.email,
            $phone: payload.user?.contacts?.phone,
            plan: payload.plan
          })
        }
        break
      case EVENTS.PURCHASE:
        await trackPurchase(eventId, payload)
        if (payload.user?.spotifyId) {
          mxp.people.set({
            plan: payload.plan,
            purchase_value: parseFloat(payload.price),
            purchase_date: new Date().toISOString()
          })
        }
        break
      default:
        return
    }
  } catch (e) {
    if (!isAxiosError(e)) {
      throw e
    }
  }
}

export default trackEvent
