'use client'

import { useState } from 'react'

const is = (step, key) => step.key === key

const useStepper = (initialKey, steps, onChange) => {
  const [key, setKey] = useState(initialKey)

  const set = (newKey) => {
    onChange && onChange()
    setKey(newKey)
  }

  const first = () => {
    onChange && onChange()
    setKey(steps[0].key)
  }

  const last = () => {
    onChange && onChange()
    setKey(steps[steps.length - 1].key)
  }

  const previous = () => {
    const idx = steps.findIndex((s) => s.key === key) - 1
    const previousIdx = idx < 0 ? steps.length - 1 : idx

    onChange && onChange()
    setKey(steps[previousIdx].key)
  }

  const next = () => {
    const idx = steps.findIndex((s) => s.key === key) + 1
    const nextIdx = idx >= steps.length ? 0 : idx

    onChange && onChange()
    setKey(steps[nextIdx].key)
  }

  const foundStep = steps.find((s) => s.key === key)
  const currentIndex = steps.findIndex((s) => s.key === key)

  if (!foundStep) {
    throw new Error('Cannot find step')
  }

  const step = {
    ...foundStep,
    index: currentIndex,
  }

  return [step, { is, set, previous, next, first, last }]
}

export { useStepper }
