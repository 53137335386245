import { createClient } from '@sanity/client'
import { SANITY_PROJECT_ID } from '@/data'

export const sanityClient = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-04-10',
})

export default sanityClient