import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const createCustomer = async () => {
  const response = await matchfyClient.post(`/transactions/customer`)
  return response.data
}

export const createPaymentIntent = async (plan, customerId, metadata) => {
  const response = await matchfyClient.post(`/transactions/intent`, {
    plan,
    customerId,
    metadata,
  })
  return response.data
}

export const createSubscription = async ({ customerId, plan, metadata }) => {
  const response = await matchfyClient.post(`/transactions/subscription`, {
    customerId,
    plan,
    metadata,
  })
  return response.data
}

export const disableAutoRenewal = async () => {
  const response = await matchfyClient.put(`/transactions/subscription/renewal/disable`)
  return response.data
}

export const enableAutoRenewal = async () => {
  const response = await matchfyClient.put(`/transactions/subscription/renewal/enable`)
  return response.data
}

export const getPaymentIntent = async (intentId) => {
  const response = await matchfyClient.get(`/transactions/intent/${intentId}`)
  return response.data
}

export const getSubscription = async () => {
  const response = await matchfyClient.get(`/transactions/subscription`)
  return response.data
}

export const listUserTransactions = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const response = await matchfyClient.get(`/transactions`, {
    params: { limit, offset },
  })
  return response.data
}

export const undoPendingTransaction = async () => {
  const response = await matchfyClient.put(`/transactions/pending/undo`)
  return response.data
}

export const updateSubscription = async ({ amount, creditsScoreUsed, plan }) => {
  const response = await matchfyClient.put(`/transactions/subscription`, {
    amount,
    creditsScoreUsed,
    plan,
  })
  return response.data
}

const endpoints = {
  createCustomer,
  createPaymentIntent,
  createSubscription,
  disableAutoRenewal,
  enableAutoRenewal,
  getPaymentIntent,
  getSubscription,
  listUserTransactions,
  undoPendingTransaction,
  updateSubscription,
}

export default endpoints
