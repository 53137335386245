'use client'

import { usePathname } from '@/i18n/routing'

export function useActiveRoute({ exact = true } = {}) {
  const pathname = usePathname()

  const getCurrentPath = () => {
    return pathname
  }

  const isActive = (route) => {
    const currentPath = getCurrentPath()
    if (exact) return currentPath === route
    return currentPath === route || currentPath.startsWith(route + '/')
  }

  return { isActive, getCurrentPath }
}

export default useActiveRoute
