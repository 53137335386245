import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const getBanner = async () => {
  const response = await matchfyClient.get('/open/banner')
  return response.data
}

export const getPlans = async (lang) => {
  const response = await matchfyClient.get(`/open/plans?lang=${lang}`)
  return response.data
}

export const getProducts = async (lang) => {
  const response = await matchfyClient.get(`/open/plans/products?lang=${lang}`)
  return response.data
}

export const getCurators = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const type = params?.type

  const response = await matchfyClient.get(`/open/curators/${type}`, {
    params: {
      limit,
      offset
    }
  })

  return response.data
}

export const getFeaturedPromos = async () => {
  const response = await matchfyClient.get('/open/promo')
  return response.data
}

export const subscribeUser = async (token) => {
  const response = await matchfyClient.get(`/open/subscribe?token=${token}`)
  return response.data
}

export const unsubscribeUser = async (token) => {
  const response = await matchfyClient.get(`/open/unsubscribe?token=${token}`)
  return response.data
}

export const getPackages = async () => {
  const response = await matchfyClient.get(`/open/plans/packages`)
  return response.data
}

export const listRoles = async () => {
  const response = await matchfyClient.get(`/open/roles/`)
  return response.data
}

const endpoints = {
  getBanner,
  getCurators,
  getFeaturedPromos,
  getPackages,
  getPlans,
  getProducts,
  listRoles,
  subscribeUser,
  unsubscribeUser
}

export default endpoints
