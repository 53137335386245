import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const createWantedArtist = async (data) => {
  const response = await matchfyClient.post('/wanted/artist', { data })
  return response.data
}

export const getWantedCounters = async (params) => {
  const { artistId } = params
  const response = await matchfyClient.get(`/wanted/${artistId}/counters`)
  return response.data
}

export const getWantedUsers = async (params) => {
  const { artistId } = params
  const response = await matchfyClient.get(`/wanted/${artistId}/users`)
  return response.data
}

export const listWanted = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { artistId, areaLevel } = params

  const query = {
    limit,
    offset,
  }

  if (areaLevel && areaLevel?.length > 0) {
    query.areaLevel = areaLevel
  }

  const response = await matchfyClient.get(`/wanted/${artistId}/list`, { params: query })
  return response.data
}

const endpoints = {
  createWantedArtist,
  getWantedCounters,
  getWantedUsers,
  listWanted
}

export default endpoints
