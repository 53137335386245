import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

const getCurator = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}`)
  return response.data
}

export const getCuratorPlaylists = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { userId } = params

  const response = await matchfyClient.get(`/curators/${userId}/playlists`, {
    params: {
      limit,
      offset
    }
  })
  return response.data
}

const getCuratorsByType = async (type) => {
  const response = await matchfyClient.get(`/curators/type/${type}`)
  return response.data
}

export const getCuratorArtists = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}/artists`)
  return response.data
}

export const getCuratorIncomingCounters = async (userId, params) => {
  const response = await matchfyClient.get(`/curators/${userId}/counters/incoming`, {
    params
  })

  return response.data
}

export const getCuratorOutgoingCounters = async (userId, params) => {
  const response = await matchfyClient.get(`/curators/${userId}/counters/outgoing`, {
    params
  })

  return response.data
}

export const getCuratorStats = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}/stats`)
  return response.data
}

export const getCuratorSearch = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const { companyName, type, roles, tags, popularity } = params

  const response = await matchfyClient.post('/curators/search', {
    limit,
    offset,
    companyName,
    type,
    roles,
    tags,
    popularity
  })

  return response.data
}

const endpoints = {
  getCurator,
  getCuratorArtists,
  getCuratorIncomingCounters,
  getCuratorOutgoingCounters,
  getCuratorPlaylists,
  getCuratorsByType,
  getCuratorSearch,
  getCuratorStats,
}

export default endpoints